<template>
    <Main>
        <Header></Header>
        <Bg>
            <div class="login-main">
                <div class="form-main">
                    <div class="title">{{$t('register.explain')}}</div>
                    <el-form ref="form" :model="item" :rules="rules" :show-message="false">
                        <el-form-item prop="company_name">
                            <el-input v-model="item.company_name" :placeholder="fieldText1">
                                <img slot="prefix" class="icon" :src="require('@/assets/img/icon/company_name.png')" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="contact">
                            <el-input v-model="item.contact" :placeholder="fieldText2">
                                <img slot="prefix" class="icon" :src="require('@/assets/img/icon/contact.png')" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="mobile">
                            <el-input v-model="item.mobile" :placeholder="fieldText3">
                                <img slot="prefix" class="icon" :src="require('@/assets/img/icon/mobile.png')" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="item.password" show-password :placeholder="fieldText4">
                                <img slot="prefix" class="icon" :src="require('@/assets/img/icon/password.png')" />
                            </el-input>
                        </el-form-item>
                        <el-button type="info" class="login-button" @click="save">{{$t('register.registerButton')}}</el-button>
                        <div class="register-text" v-if="lang == 'zh'">
                            <span class="hint">{{$t('register.hasAccount')}}</span>
                            <span class="register" @click="$router.push({path: '/login'})">{{$t('register.login')}}</span>
                        </div>
                        <div class="register-text" v-else-if="lang == 'en'">
                            <span class="register" @click="$router.push({path: '/login'})">{{$t('register.login')}}</span>
                            <span class="hint">{{$t('register.hasAccount')}}</span>
                        </div>
                    </el-form>
                </div>
            </div>
        </Bg>
        <Footer></Footer>
    </Main>
</template>

<script>
import { register } from "@/api/user.js";
import { isMobile } from "@/libs/tools.js";
import Main from '@/components/layout1/layout1-main.vue';
import Header from '@/components/layout1/layout1-header.vue';
import Footer from '@/components/layout1/layout1-footer.vue';
import Bg from '@/components/layout1/layout1-bg.vue';
import { getLanguage } from "@/lang/index.js";
export default {
    name: 'register',
    components: {
        Main,
        Header,
        Footer,
        Bg,
    },
    data() {
        const validateMobile = (rule, value, callback) => {
            if (!isMobile(value)) {
                callback(new Error(this.$t('register.checkFieldText3Format')));
            } else {
                callback(); //成功也要调用callback
            }
        }
        return {
            lang: getLanguage(),
            item: {
                company_name: '',
                contact: '',
                mobile: '',
                password: '',
            },
            rules: {
                company_name: [
                    { required: true, message: this.$t('register.fieldText1Required'), trigger: 'blur' },
                ],
                contact: [
                    { required: true, message: this.$t('register.fieldText2Required'), trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: this.$t('register.fieldText3Required'), trigger: 'blur' },
                    { validator: validateMobile, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: this.$t('register.fieldText4Required'), trigger: 'blur' },
                ],
            }
        }
    },
    computed: {
        fieldText1() {
            return this.$t('register.fieldText1');
        },
        fieldText2() {
            return this.$t('register.fieldText2');
        },
        fieldText3() {
            return this.$t('register.fieldText3');
        },
        fieldText4() {
            return this.$t('register.fieldText4');
        }
    },
    mounted() {
        document.title = this.$t("common.title8");
    },
    methods: {
        save() {
            this.$refs.form.validate((valid, err) => {
                if (valid) {
                    register(this.item).then(res => {
                        if (res.code == 0) {
                            this.$router.push({path: '/register-success'});
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    for (let key in err) {
                        this.$message.error(err[key][0]['message']);
                        return false;
                    }
                }
            });
        }
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.login-main {
    display: flex;
    justify-content: flex-end;
    .icon{
        margin-top:10px;
        width: 20px;
        height: 20px;
        margin-left: 11px;
    }
    /deep/ .el-input__inner{
        padding-left: 46px;
    }
    .form-main {
        margin-top: 80px;
        width: 420px;
        box-sizing: border-box;
        // height: 400px;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        padding: 30px 30px 24px 30px;
        .title {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 16px;
        }
        /deep/ .el-form-item {
            margin-bottom: 16px;
        }
        /deep/ .el-input {
            .el-input__inner {
                border-radius: 2px;
            }
        }
        .login-button {
            background-color: #3053A3;
            border-radius: 2px;
            width: 100%;
            border-color: #0054a5;
        }
        .register-text {
            margin-top: 21px;
            font-size: 14px;
            font-weight: 400;
            text-align: right;
            .hint {
                color: #999999;
            }
            .register {
                color: #3053A3;
                cursor: pointer;
            }
        }
    }
}
</style>
